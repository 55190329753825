<template>
  <div class="table" id="print-table">
    <div class="basic-info row">
      <div class="note col-12">
        <div class="table-date">
          日期:{{ formattedDate(dispatchData.date) }}
        </div>

        <div>
          休假:
          <span v-for="item in leaveData" :key="item.id">
            {{ item.name }}
          </span>
        </div>
        <div v-html="turnToHtml(dispatchData.note)"></div>
      </div>
      <div class="driver-list row">
        <div
          v-for="(driver, index) in dispatchData.soilCarUsers.rows"
          :key="driver.id"
          class="driver-item col-2"
          :class="{ isLastRow: isLastRow(index) }"
        >
          <div>{{ driver.sn }}</div>
          <div class="userName">{{ driver.user_name }}</div>
        </div>
      </div>
    </div>
    <div class="page-break"></div>
    <table class="log-table mt-2">
      <thead>
        <tr>
          <th v-for="head in fields" :key="head.ket" :style="head.thStyle">
            {{ head.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in dispatchData.soilDispatchs" :key="item.id">
          <td>{{ shiftName(item.work_day) }}</td>
          <td>{{ item.start_area }}</td>
          <td>{{ item.type }}</td>
          <td>
            <p v-for="car in item.start_car_user_list" :key="car.id">
              <span class="mr-1">{{ car.sn }}</span
              ><span class="ml-1">{{ car.user_name }}</span>
            </p>
          </td>
          <td>
            <p v-for="car in item.end_car_user_vehicle_list" :key="car.id">
              <span class="mr-1">{{ car.sn }}</span
              ><span class="ml-1">{{ car.user_name }}</span>
            </p>
          </td>
          <td>{{ item.end_area }}</td>
          <td>
            <p v-for="car in item.end_car_user_list" :key="car.id">
              <span class="mr-1">{{ car.sn }}</span
              ><span class="ml-1">{{ car.user_name }}</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

moment.locale('zh-tw'); 

moment.updateLocale('zh-tw', {
  weekdays: ['日', '一', '二', '三', '四', '五', '六']
});
  
export default {
  name: "Table",
  data() {
    return {
      dispatchData: [],
      leaveData: [],
      fields: [
        { key: "shiftType", label: "班別", thStyle: { width: "9%" } },
        { key: "startArea", label: "施工區域", thStyle: { width: "10%" } },
        { key: "type", label: "施工内容", thStyle: { width: "13%" } },
        {
          key: "startCarUserList",
          label: "施工機具",
          thStyle: { width: "19%" },
        },
        {
          key: "endCarUserVehicleList",
          label: "施工車輛",
          thStyle: { width: "18%" },
        },
        { key: "endArea", label: "土尾區域", thStyle: { width: "10%" } },
        { key: "endCarUserList", label: "土尾機具", thStyle: { width: "20%" } },
      ],
    };
  },
  created() {
    this.dispatchData = JSON.parse(localStorage.getItem("dispatchData"));
    this.getLeaveData();
  },
  computed: {
    // ...mapState("dispatch", ["dispatchData"]),
  },
  methods: {
    getLeaveData() {
      const params = {};
      params.start = moment(this.dispatchData.date).startOf('day').toISOString();
      params.end = moment(this.dispatchData.date).endOf('day').toISOString();
      axios.get(`/leave`, { params }).then((res) => {
        this.leaveData = res.data;
      });
    },
    shiftName(shift) {
      switch (shift) {
        case 0:
          return "早班";
        case 1:
          return "早班加班";
        case 2:
          return "晚班加班";
      }
    },
    formattedDate(inp) {
      const date = moment(inp);
      return date.format('YYYY年M月D日 (dddd)');
    },
    turnEnToZh(num) {
      switch (num) {
        case 0:
          return "日";
        case 1:
          return "一";
        case 2:
          return "二";
        case 3:
          return "三";
        case 4:
          return "四";
        case 5:
          return "五";
        case 6:
          return "六";
      }
    },
    isLastRow(index) {
      if (
        index >=
        this.dispatchData.soilCarUsers.rows.length -
          (this.dispatchData.soilCarUsers.rows.length % 6)
      )
        return true;
    },
    turnToHtml(string) {
      return string.replace(/(\r\n)|(\n)/g, "<br>");
    },
  },
};
</script>
<style lang="scss">
.basic-info {
  .table-date {
    text-align: left;
    font-size: 20px;
  }
  .note {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .driver-list {
    margin: 0 15px;
    border: #dee2e6 1px solid;
    .driver-item {
      display: flex;
      padding: 10px;
      &:not(.isLastRow) {
        border-bottom: #dee2e6 1px solid;
      }
      &:not(:nth-child(6n)) {
        border-right: #dee2e6 1px solid;
      }
      .userName {
        margin-left: 5px;
      }
    }
  }
}
#print-table {
  width: 100%;
  thead {
    th {
      border: 1px solid #dee2e6;
    }
  }
  tbody {
    tr {
      td {
        border: 1px solid #dee2e6;
      }
    }
  }
  p {
    margin-bottom: 0 !important;
  }
}
@media print {
  .page-break {
    page-break-before: always;
  } /* page-break-after works, as well */

  @page {
    size: A4 portrait;
    margin: 0cm;
  }
  .back-btn,
  header,
  footer,
  .pagination,
  .pagination-info,
  .page-select {
    display: none;
  }
  footer {
    display: none;
  }
  .sidebar {
    display: none;
    width: 0;
  }
  .main-panel {
    width: 100%;
  }
}
</style>
