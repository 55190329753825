<template>
  <div class="dispatchData">
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category"></div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            class="select-default page-select"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-12">
          <table class="log-table mt-2">
            <thead>
              <tr>
                <th>日期</th>
                <th>建立時間</th>
                <th>派工系統</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in Object.values(tableData)" :key="item.id">
                <td>
                  {{ item[0].date.substr(0, 10) }}
                </td>
                <td>
                  {{ getDate(item[0].createdAt) }}
                </td>
                <td>派工系統{{ item[0].name }}</td>
                <td>
                  <el-button @click="setShift(0, item)">早班加班</el-button>
                  <el-button @click="setShift(1, item)">一般班</el-button>
                  <el-button @click="setShift(2, item)">晚班加班</el-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            class="pull-right"
            v-model="pagination.currentPage"
            @change-page="handleCurrentChange"
            :per-page="pagination.perPage"
            @per-page="handleSizeChange"
            :total="pagination.total"
          >
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Loading,
  Button,
} from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
import axios from "axios";
import moment from "moment";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Loading);
Vue.use(Button);
export default {
  components: {
    PPagination,
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 200, 400],
        total: 0,
      },
      tableData: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {},
  methods: {
    getDate(date) {
      return moment(date).local().format("YYYY-MM-DD HH:mm:ss");
    },
    setShift(shift, data) {
      this.$store.commit(
        "dispatch/setShift",
        data.find((e) => e.work_day === shift)
      );
      localStorage.setItem(
        "dispatchData",
        JSON.stringify(data.find((e) => e.work_day === shift))
      );
      const routeData = this.$router.resolve({ path: "/soil-print" });
      window.open(routeData.href, "_blank");
    },
    groupItems(array, property) {
      return array.reduce(function (groups, item) {
        var name = item[property];
        var group = groups[name] || (groups[name] = []);
        group.push(item);
        return groups;
      }, {});
    },
    getData() {
      const params = {};
      params.limit = this.pagination.perPage;
      params.index = this.pagination.currentPage;
      this.loading = true;
      axios.get(`/soil/logs`, { params }).then((res) => {
        this.tableData = this.groupItems(res.data.rows, "date");
        this.pagination.total = res.data.count;
        this.loading = false;
      });
    },
    handleSizeChange(size) {
      this.pagination.perPage = size;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.getData();
    },
  },
};
</script>
<style lang="scss">
@media print {
  @page {
    size: A4 portrait;
    margin: 0cm;
  }
  header,
  footer,
  .pagination,
  .pagination-info,
  .page-select {
    display: none;
  }
  footer {
    display: none;
  }
  .sidebar {
    display: none;
    width: 0;
  }
  .main-panel {
    width: 100%;
  }
}
.log-table {
  width: 100%;
  color: #606266;
  border: 1px solid #ebeef5;
  th {
    padding: 12px 10px;
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
  }
  td {
    padding: 12px 10px;
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
  }
}
</style>
